<template>
  <div>
    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />

      <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('labels.search')"
          />
          <b-button
            v-if="typeUserLoged == 'Root'"
            class="text-nowrap"
            variant="primary"
            @click="activeSidebarAddProduct = true"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">{{ $t("buttons.addproducts") }}</span>
          </b-button>
          <b-sidebar
            sidebar-class="sidebar-xl"
            id="sidebar-addEditProduct"
            bg-variant="white"
            right
            backdrop
            shadow
            no-header
            :visible="activeSidebarAddProduct"
            @change="changeSidebarActive"
          >
            <addEditProduct
              ref="addEditProduct"
              @hiddentSidebar="activeSidebarAddProduct = false"
              @getProducts="getProducts()"
            />
          </b-sidebar>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="products"
        :sort-options="{ enabled: false }"
        styleClass="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'status'">
            <b-badge
              pill
              :variant="`light-${props.row.status ? 'success' : 'danger'}`"
              class="text-capitalize"
            >
              {{ props.row.status ? "Active" : "Inactive" }}
            </b-badge>
          </div>
          <div v-else-if="props.column.field == 'brands'">
            <ul v-if="props.row.brands && props.row.brands.length > 0">
              <li v-for="(tr, index) in props.row.brands" :key="index">
                {{ tr }}
              </li>
            </ul>
            <p class="ml-2" v-else>-</p>
          </div>
          <div v-else-if="props.column.field == 'games'">
            <b-badge
              pill
              :variant="`light-${props.row.isgame ? 'secondary' : 'primary'}`"
              class="text-capitalize"
            >
              {{
                props.row.isgame
                  ? "Product game"
                  : `${props.row.gamesTotal} games`
              }}
            </b-badge>
          </div>
          <div v-else-if="props.column.field == 'ispayment'">
          <b-badge
            pill
            :variant="`light-${props.row.ispayment ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            {{ props.row.ispayment ? "isPayment" : "notPayment" }}
          </b-badge>
        </div>
          <div v-else-if="props.column.field == 'createdAt'">
            <div>{{ formatDate(props.row.createdAt) }}</div>
          </div>

          <div v-else-if="props.column.field == 'actions'">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="
                  typeUserLoged == 'Root' &&
                  (props.row.isImportGamesApi || props.row.isImportGamesArchive)
                "
                @click="ImportgamesApi(props.row)"
              >
                <feather-icon icon="RefreshCcwIcon" />
                <span class="align-middle ml-50">Games api</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'games-list',
                  params: { producSelectId: props.row._id },
                }"
              >
                <feather-icon icon="CpuIcon" />
                <span class="align-middle ml-50">Games</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="typeUserLoged == 'Root'"
                @click="editProduct(props.row)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="typeUserLoged == 'Root'"
                @click="confirmDelete(props.row)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="typeUserLoged == 'Root'"
                @click="showModalDocumentation(props.row)"
              >
                <feather-icon icon="FileIcon" />
                <span class="align-middle ml-50">Documentation</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
              v-if="typeUserLoged == 'Root'"
              @click="$router.push({ name: 'product.config', params: { id: props.row._id } })">
              <feather-icon icon="SettingsIcon" />
              <span class="align-middle ml-50">Add Config</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="typeUserLoged == 'Root'"
              @click="openModalCredentials(props.row)">
              <feather-icon icon="KeyIcon" />
              <span class="align-middle ml-50">Add Credentials</span>
            </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>
      </vue-good-table>
      <importExcel
        ref="importGameByExcel"
        @changeImportGameLoading="confirmImportGamesApi"
      />
    </b-card>
    <b-modal
      v-model="showCredentials"
      centered
      title="ADD CREDENTIALS"
      ok-title="Add"
      @ok="saveCredentialsJson"
      ok-only
    >
      <b-row>
        <b-col md="12">
          <validation-observer>
            <b-form class="p-2">
              <validation-provider>
                <b-form-group label="Json">
                  <b-form-input v-model="credentialsJson.json" />
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
    <documentation ref="documentation" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BProgress,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import productStoreModule from "@/store/products";
import addEditProduct from "./addEditProduct";
import importExcel from "./importExcel";
import documentation from "./modalDocumentation";
import store from "@/store";
import { formatDate } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver, validate } from "vee-validate";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BProgress,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BSpinner,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    addEditProduct,
    importExcel,
    BFormCheckbox,
    documentation,
  },
  data: () => ({
    showCredentials: false,
    processing: false,
    loadingData: false,
    searchQuery: "",
    timeOutSearch: null,
    activeSidebarAddProduct: false,
    credentialsJson: {
      json: null,
      productId: null,
    },
  }),
  computed: {
    columns() {
      let columns = [
        { label: "Product", field: "name" },
        { label: "Product Id", field: "productId" },
        { label: "Description", field: "description" },
        { label: "Game", field: "games" },
        { label: "Payment", field: "ispayment" },
        { label: "Brands", field: "brands" },
        { label: "Status", field: "status" },
        { label: "Created", field: "createdAt" },
      ];

      if (this.typeUserLoged == "Root" || this.typeUserLoged == "admin") {
        columns.push({ label: "Actions", field: "actions" });
      }

      return columns;
    },
    products() {
      return this.$store.state.productsModule.products;
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
  },
  watch: {
    searchQuery(val) {
      clearTimeout(this.timeOutSearch);
      this.timeOutSearch = setTimeout(() => {
        this.getProducts();
      }, 800);
    },
  },
  methods: {
    openModalCredentials(row) {
      this.credentialsJson.productId = row._id;
      this.showCredentials = !this.showCredentials;
    },
    showModalDocumentation(product) {
      this.$refs.documentation.openModal = true;
      this.$refs.documentation.resetModalData();
      this.$refs.documentation.product = product;
    },
    async saveCredentialsJson() {
      try {
        this.loadingData = true;
        await this.$store
          .dispatch("productsModule/addCredentialsJson", {
            credentials: JSON.parse(this.credentialsJson.json),
            productId: this.credentialsJson.productId,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Product successfully credentials",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.showCredentials = false;
            this.credentialsJson = {
              json: null,
              productId: null,
            };
          });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    formatDate(fecha) {
      const dt = new Date(fecha);
      return formatDate(dt);
    },
    async getProducts() {
      try {
        this.loadingData = true;
        const payload = {
          q: this.searchQuery,
        };
        await this.$store.dispatch("productsModule/getProducts", payload);
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    confirmDelete(val) {
      this.$bvModal
        .msgBoxConfirm(`Do you want to remove the ${val.name} product?`, {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteProduct(val);
        });
    },
    async deleteProduct(val) {
      try {
        this.loadingData = true;
        await this.$store.dispatch("productsModule/deleteProduct", val._id);
        this.getProducts();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product successfully removed",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async ImportgamesApi(product) {
      if (product.isImportGamesArchive) {
        this.$refs.importGameByExcel.modalShow = true;
        this.$refs.importGameByExcel.productId = product.productId;
        return;
      }
      const productId = product.productId;
      this.confirmImportGamesApi({ productId });
    },

    async confirmImportGamesApi({ productId, file }) {
      try {
        const resultConfirm = await this.$swal({
          title: "Please Confirm",
          text: `Are you sure to import the ${productId} games from the api?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, import!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (resultConfirm.value) {
          this.$refs.importGameByExcel.modalShow = false;
          this.showProcessImportApiGame(true);

          const res = await this.$store.dispatch(
            "moduleProducts/importGameApi",
            { productId, file }
          );

          if (res?.data?.message === "Not found") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${res?.data?.message} for ${productId}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `The games were imported for ${productId}`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.getProducts();
          }
        }

        this.showProcessImportApiGame(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.showProcessImportApiGame(false);
        this.$swal.close();
      }
    },

    showProcessImportApiGame(isShow) {
      if (isShow) {
        this.$swal({
          title: "Importing Games...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      } else {
        this.$swal.close();
      }
    },

    editProduct(val) {
      this.activeSidebarAddProduct = true;
      this.$refs.addEditProduct.modeEdit = true;
      const IdsBrands = val?.brands.map((brand) => brand._id);
      this.$refs.addEditProduct.product = {
        _id: val?._id,
        name: val?.name,
        description: val?.description,
        brands: IdsBrands,
        credentials: val?.credentials,
        // production: val?.production[0],
        uploadType: val?.uploadType,
        // auth: val?.auth[0],
        // bet: val?.bet[0],
        // win: val?.win[0],
        // rollback: val?.rollback[0],
        // settings: val?.settings[0],
        url_testing: val?.url_testing,
        url_production: val?.url_production,
        games_api_url: val?.games_api_url,
        status: val?.status,
        isgame: val?.isgame,
        url_document: val.url_document,
        path_document: val.path_document,
        documentationCredentials: val.documentationCredentials,
        ispayment: val.ispayment,
        isImportGamesApi: val?.isImportGamesApi,
        isImportGamesArchive: val?.isImportGamesArchive,
        json: JSON.stringify(val?.json),
        category: val?.category,
      };
    },
    changeSidebarActive(val) {
      if (!val) this.$refs.addEditProduct.modeEdit = false;
      this.activeSidebarAddProduct = val;

      this.$refs.addEditProduct.getBrands();
    },
  },
  mounted() {
    this.getProducts();
  },
  created() {
    // Register module
    if (!store.hasModule("productsModule"))
      store.registerModule("productsModule", productStoreModule);
  },
  destroyed() {
    if (store.hasModule("productsModule"))
      store.unregisterModule("productsModule");
  },
};
</script>

<style lang="scss">
#sidebar-addEditProduct {
  width: 50% !important;
}
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
